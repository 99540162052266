<template>
  <div>
    <!-- 快捷图标列表 -->
    <el-row :gutter="20">
      <el-col :span="24">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <!--                        <i class="el-icon-s-management"></i> <span>设备列表</span>-->
            <!--                        <el-button style="float: right; padding: 3px 0" type="text"><i class="el-icon-s-fold"></i>-->
            <!--                        </el-button>-->
            <el-radio-group v-model="radio1">
              <el-radio-button :label=item v-for="(item,index) in group_data" :key="index"
                               @click.native="overall_fdi_type_click(item)">{{ item }}
              </el-radio-button>
            </el-radio-group>
          </div>
          <el-row :gutter="20">
            <div class="ipu-list">
              <el-col class="iconList" v-for="item in device_infos" :key="item.title" :span="6">
                <div v-if="item[1]">
                  <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix" style="height: 10px">
                      <span style="color: #42b983;font-weight: bold">设备可用</span>
                    </div>

                    <div class="text item" style="height: 100px;width: 400px" @click="showImagePreview(item[6])">
                      <div
                          style="width: 130px;height: 70px;background-color: #666666;align-items: center;justify-content: center;display: flex;float: left">
                        <img :src=item[6] style="width: 130px;height: 70px">
                      </div>
                      <div style="float: left;margin-left: 5px">


                        <el-row>
                          <el-col :span="5">
                            <div>cpu</div>
                          </el-col>
                          <el-col :span="15">
                            <Progress :width="100" :progress="item[5].cpu_info[2]"
                                      :strokeWidth="10"
                                      :showInfo="true"/>
                          </el-col>
                          <el-col :span="4">
                            <div class="grid-content bg-purple-dark"
                                 style="margin-left: 10px;font-size: 13px">
                              {{ item[5].cpu_info[2] }}%
                            </div>
                          </el-col>
                        </el-row>

                        <el-row>
                          <el-col :span="5">
                            <div class="grid-content bg-purple-dark">内存</div>
                          </el-col>
                          <el-col :span="15">
                            <Progress :width="100" :progress="item[5].memory_info[1]"
                                      :strokeWidth="10"
                                      :showInfo="true"/>
                          </el-col>
                          <el-col :span="4">
                            <div class="grid-content bg-purple-dark"
                                 style="margin-left: 10px;font-size: 13px">
                              {{ item[5].memory_info[1] }}%
                            </div>
                          </el-col>
                        </el-row>

                        <el-row>
                          <el-col :span="5">
                            <div class="grid-content bg-purple-dark">磁盘</div>
                          </el-col>
                          <el-col :span="15">
                            <Progress :width="100" :progress="item[5].disk_info[1]"
                                      :strokeWidth="10"
                                      :showInfo="true"/>
                          </el-col>
                          <el-col :span="4">
                            <div class="grid-content bg-purple-dark"
                                 style="margin-left: 10px;font-size: 13px">
                              {{ item[5].disk_info[1] }}%
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                      <el-row>
                        <el-col :span="6">
                          <div>
                            <div>
                              <div v-if="item[4]">

                                <!--                                                                <el-popover trigger="hover" placement="top">-->
                                <!--                                                                  <p>ip: {{ item[3] }}</p>-->
                                <!--&lt;!&ndash;                                                                  <p>住址: {{ scope.row.address }}</p>&ndash;&gt;-->
                                <!--                                                                  <div slot="reference" class="name-wrapper">-->
                                <!--                                                                    <el-tag type="success">摄像头连接正常</el-tag>-->
                                <!--                                                                  </div>-->
                                <!--                                                                </el-popover>-->
                                <el-tooltip class="item" effect="dark" :content="'ip : '+item[3]" placement="top-start">
                                  <el-tag type="success">摄像头连接正常</el-tag>
                                </el-tooltip>
                              </div>
                              <div v-else>
                                <el-tooltip class="item" effect="dark" :content="'ip : '+item[3]" placement="top-start">
                                  <el-tag type="danger">摄像头连接异常</el-tag>
                                </el-tooltip>
                              </div>
                            </div>
                          </div>
                        </el-col>
                        <el-tooltip class="item" effect="dark" :content="'地址 : '+item[2]" placement="top-start">
                          <el-tag type="info">
                            <div
                                style="text-overflow: ellipsis;-o-text-overflow:ellipsis;white-space: nowrap;overflow:hidden;width: 230px;height: 50px">
                              {{ item[2] }}
                            </div>
                          </el-tag>
                        </el-tooltip>
                      </el-row>
                    </div>
                  </el-card>
                </div>

                <div v-else>
                  <el-card class="box-card" shadow="hover">
                    <div slot="header" class="clearfix" style="height: 10px">
                      <span style="color: red;font-weight: bold">设备不可用</span>
                    </div>

                    <div class="text item" style="height: 100px;width: 400px" @click="showImagePreview(item[6])">
                      <div
                          style="width: 130px;height: 70px;background-color: #666666;align-items: center;justify-content: center;display: flex;float: left">
                        <img :src=item[6] style="width: 130px;height: 70px">
                      </div>
                      <div style="float: left;margin-left: 5px">
                        <el-row>
                          <el-col :span="5">
                            <div>cpu</div>
                          </el-col>
                          <el-col :span="15">
                            <Progress :width="100" :progress="0" :strokeWidth="10"
                                      :showInfo="true"/>
                          </el-col>
                          <el-col :span="4">
                            <div class="grid-content bg-purple-dark"
                                 style="margin-left: 10px;font-size: 13px">
                              0.00%
                            </div>
                          </el-col>
                        </el-row>

                        <el-row>
                          <el-col :span="5">
                            <div class="grid-content bg-purple-dark">内存</div>
                          </el-col>
                          <el-col :span="15">
                            <Progress :width="100" :progress="0" :strokeWidth="10"
                                      :showInfo="true"/>
                          </el-col>
                          <el-col :span="4">
                            <div class="grid-content bg-purple-dark"
                                 style="margin-left: 10px;font-size: 13px">
                              0.00%
                            </div>
                          </el-col>
                        </el-row>

                        <el-row>
                          <el-col :span="5">
                            <div class="grid-content bg-purple-dark">磁盘</div>
                          </el-col>
                          <el-col :span="15">
                            <Progress :width="100" :progress="0" :strokeWidth="10"
                                      :showInfo="true"/>
                          </el-col>
                          <el-col :span="4">
                            <div class="grid-content bg-purple-dark"
                                 style="margin-left: 10px;font-size: 13px">
                              0.00%
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                    </div>
                  </el-card>
                </div>
              </el-col>
            </div>
          </el-row>
        </el-card>
      </el-col>
    </el-row>
    <div class="wrap">
    </div>

  </div>

</template>

<script>

import Progress from '../components/Progress'
import {getDeviceManagement, queryGroup} from "../../api/requests";

export default {
  name: "DeviceManagement",
  components: {
    Progress,
  },
  data() {
    return {
      radio1: '',
      group_data: [],
      device_infos: [],
      camera_flag: {true: '', false: ''},
      polling: "",

    }
  },
  mounted() {
    console.log('---------------------------')
    // this.getDeviceData();
    // this.pollDeviceData()
  },
  created() {
    console.log('==================')
    this.resetNvaMenu();
  },

  methods: {
    resetNvaMenu() {
      let user = localStorage.getItem('Authorization');
      queryGroup({'info': user}).then(res => {
        if (res.data.info.factoryName) {
          this.radio1 = res.data.info.factoryName[0]
          console.log('this.radio1this.radio1this.radio1this.radio1', this.radio1)
          res.data.info.factoryName.forEach(res => {
            console.log(res)
            this.group_data.push(res)
          })
        }
      }).then(val => {
        console.log(val)
        this.getDeviceData()
      })
    },
    showImagePreview(imageUrl) {
      console.log('imageUrl============', imageUrl)
      const image = new Image();
      image.src = imageUrl;
      image.onload = () => {
        // 创建弹出层
        const previewContainer = document.createElement('div');
        previewContainer.style.position = 'fixed';
        previewContainer.style.top = 0;
        previewContainer.style.bottom = 0;
        previewContainer.style.left = 0;
        previewContainer.style.right = 0;
        previewContainer.style.backgroundColor = 'rgba(0,0,0,0.8)';
        previewContainer.style.display = 'flex';
        previewContainer.style.justifyContent = 'center';
        previewContainer.style.alignItems = 'center';
        document.body.appendChild(previewContainer);
        // 在弹出层中添加图片
        const previewImage = document.createElement('img');
        previewImage.src = imageUrl;
        previewImage.style.maxWidth = '80%';
        previewImage.style.maxHeight = '80%';
        previewContainer.appendChild(previewImage);
        // 点击弹出层，关闭预览
        previewContainer.addEventListener('click', () => {
          document.body.removeChild(previewContainer);
        });
      };
    },
    overall_fdi_type_click(item) {
      // eslint-disable-next-line no-console
      console.log(item)
      getDeviceManagement({factory: item}).then(res => {
        this.device_infos = res.data.info.ipu
      })
    },
    getDeviceData() {
      // let param = this.$route.query // url参数
      console.log('this.group_data=========', this.group_data)
      let query_name = this.group_data[0]
      console.log('radio1=========', this.radio1)
      getDeviceManagement({factory: query_name}).then(res => {
        console.log('res========', res.data.info.ipu)
        this.device_infos = res.data.info.ipu
      })
    },
    pollDeviceData() {
      clearInterval(this.polling);
      this.polling = setInterval(() => {
        // eslint-disable-next-line no-console
        console.log('play2222222222')
        let param = this.$route.query // url参数
        getDeviceManagement(param).then(res => {
          console.log('res========', res.data.info.ipu)
          // this.device_infos = res.data.info.ipu
        })
      }, 10000);
    }
  },
  beforeDestroy() {
    clearInterval(this.polling);
    this.polling = null;
    console.log('销毁定时器')
  }
}
</script>

<style scoped>
.box-card {
  /*width: 250px;*/
  /*height: 250px;*/
}

.camera-list {
  /*margin-top: 15px;*/
  height: 800px;
  overflow: auto;
}

.ipu-list {
  height: 800px;
  overflow: auto;
}

</style>